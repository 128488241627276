$(function () {
    var $especialidades = $('.home-especialidades .swiper-container');

    if(!$especialidades.length) return

    var mySwiper = new Swiper ($especialidades, {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
            prevEl: $especialidades.closest('.home-especialidades').find('.button-prev'),
            nextEl: $especialidades.closest('.home-especialidades').find('.button-next')
        },
        autoplay: {
            delay: 4000,
        },
        speed: 550,
        loop: true,
        breakpoints: {
            1359: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 1
            },
        }
    });

    $especialidades.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});