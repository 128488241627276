// Form Contato
$(function() {    
    $formContact = $('#form_contato');

    if(!$formContact.length) return

    $formButton = $formContact.find('.button');
    
    $formButton.click(function(e) {
    
        e.preventDefault();
    
        var form        = $formContact;
        var button      = $(this);
    
        form.validate({
            errorElement: 'span',
            rules: {
                nome: {
                    required: true,
                    minlength: 3,
                    maxlength: 40
                },
                email: {
                    required: true,
                    validaEmail: true
                },
                telefone: {
                    required: true,
                    minlength: 14
                },
                assunto: {
                    required: true
                },
                mensagem: {
                    required: true
                }
            },

            messages: {
                nome: {
                    required: 'Por favor, informe seu Nome!',
                    minlength: 'Digite pelo menos {0} caracteres.',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                },
                email: 'Por favor, informe um E-mail válido!',
                telefone: {
                    required: 'Por favor, informe seu Telefone!',
                    minlength: 'Por favor, informe um Telefone válido!'
                },
                assunto: {
                    required:'Por favor, informe o Assunto!'
                },
                mensagem: {
                    required: 'Por favor, informe sua Mensagem!',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                }
            }
        });
    
        if(form.valid() === true) {
            // Ajax para enviar o formulário
            
            return
        }
    });

    // Custom Validate E-mail
    $.validator.addMethod("validaEmail", function(value, element) {
        var exclude=/[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
        var check=/@[\w\-]+\./;
        var checkend=/\.[a-zA-Z]{2,3}$/;
        if(((value.search(exclude) != -1)||(value.search(check)) == -1)||(value.search(checkend) == -1)){return false;}
        else {return true;}
    }, 'Por favor, informe um E-mail válido!');
});