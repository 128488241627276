$(function() {
    var $btnShowMenu = $('.btn-toggle-menu');
    var $btnCloseMenu = $('.btn-close-menu');

    $btnShowMenu.on('click', function(e) {

        e.preventDefault();

        var $this = $(this);

        $btnShowMenu.addClass('active');
        $btnShowMenu.next().addClass('show');
    });

    $btnCloseMenu.on('click', function(e) {

        e.preventDefault();
        
        $btnShowMenu.removeClass('active');
        $btnCloseMenu.parent().removeClass('show');
    });
});