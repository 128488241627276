$(function () {
    var $procedimentos = $('.home-procedimentos .swiper-container');

    if(!$procedimentos.length) return

    var mySwiper = new Swiper ($procedimentos, {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
            prevEl: $procedimentos.closest('.home-procedimentos').find('.button-prev'),
            nextEl: $procedimentos.closest('.home-procedimentos').find('.button-next')
        },
        autoplay: {
            delay: 4000,
        },
        speed: 550,
        loop: true,
        breakpoints: {
            1359: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 1
            },
        }
    });

    $procedimentos.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});