$(function() {
    $selecField = $('form select');
    
    $selecField.each(function(index, element) {
        var $field = $(element);

        $field.select2({
            language: "pt-BR"
        });
    });
});