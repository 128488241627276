$(function () {
    var $convenios = $('.home-convenios .swiper-container');

    if(!$convenios.length) return

    var mySwiper = new Swiper ($convenios, {
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
            prevEl: $convenios.parent().find('.button-prev'),
            nextEl: $convenios.parent().find('.button-next')
        },
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
                spaceBetween: 10
            }
        }
    })
});