$(function () {
    var $gallery = $('.box-gallery .swiper-container');

    if(!$gallery.length) return

    var mySwiper = new Swiper ($gallery, {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            prevEl: $gallery.parent().find('.button-prev'),
            nextEl: $gallery.parent().find('.button-next')
        },
        autoplay: {
            delay: 4000,
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            575: {
                slidesPerView: 1
            },
        }
    })
});