$(function () {
    var $vantagens = $('.box-vantagens .swiper-container');

    if(!$vantagens.length) return

    var mySwiper = new Swiper ($vantagens, {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            prevEl: $vantagens.parent().find('.button-prev'),
            nextEl: $vantagens.parent().find('.button-next')
        },
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    })
});