$(function() {
    $elementScrollBlock = $('.elementScrollBlock');

    if(!$elementScrollBlock.length) return

    var scrollorama = $.scrollorama({
        enablePin: false,
        blocks:'.elementScrollBlock'
    });

    if($('.page-home').length) {
        scrollorama.animate('.home-institucional .img-maca', {
            duration: 1800,
            property:'right',
            start: 0,
            end: -150
        });
    
        scrollorama.animate('.home-institucional .img-maca-small', {
            duration: 1800,
            property:'right',
            start: -20,
            end: 100
        });
    }

    if($('.page-about').length) {
        scrollorama.animate('.page-about .img-maca', {
            duration: 1500,
            property:'bottom',
            start: -65,
            end: 150
        });

        scrollorama.animate('.page-about .img-maca-small', {
            duration: 1500,
            property:'left',
            start: -5,
            end: 50
        });
    }

    if($('.page-procedimentos-especialidades-detalhe').length) {
        scrollorama.animate('.page-procedimentos-especialidades-detalhe .img-maca', {
            delay: 400,
            duration: 1500,
            property:'left',
            start: -120,
            end: 150
        });

        scrollorama.animate('.page-procedimentos-especialidades-detalhe .img-maca-small', {
            delay: 400,
            duration: 1500,
            property:'left',
            start: 0,
            end: 100
        });
    }

    if($('.page-contact').length) {
        scrollorama.animate('.page-contact .img-maca', {
            duration: 1500,
            property:'right',
            start: 0,
            end: 150
        });

        scrollorama.animate('.page-contact .img-maca-small', {
            duration: 1500,
            property:'right',
            start: 15,
            end: 100
        });
    }

    if($('.box-calculo-imc').length) {
        scrollorama.animate('.box-calculo-imc .img-balanca', {
            // delay: ($('.box-calculo-imc').offset().top - 300),
            duration: 1500,
            property:'top',
            start: 50,
            end: -10
        });
    }
});