$(function () {
    var $equipeMedica = $('.box-equipe-medica .swiper-container');

    if(!$equipeMedica.length) return

    var mySwiper = new Swiper ($equipeMedica, {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            prevEl: $equipeMedica.parent().find('.button-prev'),
            nextEl: $equipeMedica.parent().find('.button-next')
        },
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    })
});