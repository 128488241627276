$(function () {
    var $equipe = $('.box-equipe .swiper-container');

    if(!$equipe.length) return

    var mySwiper = new Swiper ($equipe, {
        slidesPerView: 5,
        spaceBetween: 30,
        navigation: {
            prevEl: $equipe.parent().find('.button-prev'),
            nextEl: $equipe.parent().find('.button-next')
        },
        autoplay: {
            delay: 7000,
        },
        breakpoints: {
            1199: {
                slidesPerView: 4
            },
            991: {
                slidesPerView: 3
            },
            767: {
                slidesPerView: 2
            },
            575: {
                slidesPerView: 1
            },
        }
    })
});