$(function() {
    var $boxImc = $('.box-calculo-imc');

    if(!$boxImc.length) return

    var $peso = $boxImc.find('#peso');
    var $altura = $boxImc.find('#altura');
    var values = {
        peso: 0,
        altura: 0
    }

    $peso.on('blur', function(e) {

        var value = parseFloat(e.currentTarget.value);

        if(value) {
            if(e.currentTarget.value.indexOf(",")) {
                var newValue = e.currentTarget.value.replace(",", ".");
                value = newValue;
            }

            values.peso = value;
            
            return calculaIMC(values);
        }
    });

    $altura.on('blur', function(e) {
        var value = parseInt(e.currentTarget.value);

        if(value) {

            values.altura = value;

            return calculaIMC(values);
        }
    });

    function calculaIMC(values) {

        if(values.peso && values.altura) {
            var imc = 0;
            var peso = values.peso;
            var altura = (values.altura / 100);

            imc = peso / Math.pow(altura, 2);

            imc = financial(imc);

            /* if(imc < 17) {
                console.log('Muito abaixo do peso');
            } else if(imc >=17 && imc <= 18.49) {
                console.log('Abaixo do peso');
            } else if(imc >=18.5 && imc <= 24.99) {
                console.log('Peso normal');
            } else if(imc >=25 && imc <= 34.99) {
                console.log('Obesidade I');
            } else if(imc >=35 && imc <= 39.99) {
                console.log('Obesidade II (severa)');
            } else {
                console.log('Obesidade III (mórbida)');
            } */

            console.log(imc);
        } else {
            return
        }
    }

    function financial(x) {
        return Number.parseFloat(x).toFixed(2);
      }
});